import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout/layout";
import DestinationsBoxSection from "../components/destinations/destinations-box-section";
import IntegrationFilterSection from "../components/integrations/integrations-filter-section";
import IntegrationCategory from "../components/integrations/integration-category";
import IntegrationEndpointsGrid from "../components/integrations/integration-endpoints-grid";
import IntegrationDocumentation from "../components/integrations/integration-documentation";
import IntegrationFeaturesSection from "../components/integrations/integration-features-section";
import Seo from "../components/misc/seo";
import { useVideoSelector } from "../hooks/VideoSelector";
import VideoContainer from "../components/misc/video-container";
import MarkdownContainer from "../components/misc/markdown-container";
import SectionSeparator from "../components/layout/section-separator";
import CTASection from "../components/misc/cta-section";
import TestimonialSection from "../components/layout/testimonial-section";
import WhyChooseStitch from "../components/layout/why-choose-stitch";
import AnalyticsBlocks from "../components/integrations/analytics-blocks";

const Integration = ({ data, pageContext }) => {
    const menus = pageContext.menus?.items;
    const integration = data.contentfulIntegration;
    const bodyClasses = `${pageContext.type + "s"} integrations_${integration.url}`;
    const selectedVideo = useVideoSelector("integration", [integration.type_id]);
    const customerQuote = integration.customer ?? null;

    let useConnectorTemplate = false;
    let pageTitle = `ETL your ${integration.name} data to your data warehouse`;
    let pageDescription = `ETL your ${integration.name} data to your data warehouse. Faster and more reliable than writing and maintaining ETL scripts.`;
    let pageHeading = `ETL your ${integration.name} data to your data warehouse`;
    let pageSubHeading = null;

    if (["Import API", "Webhooks", "webhooks", "SFTP", "FTP/SFTP/FTPS", "Zapier"].includes(integration.name)) {
        useConnectorTemplate = true;
        pageTitle = `ETL any data to your data warehouse with the Stitch ${integration.name} integration`;
        pageDescription = `ETL any data to your data warehouse with the Stitch ${integration.name} integration. Faster and more reliable than writing and maintaining ETL scripts.`;
        pageHeading = `ETL your data <div id='nowarehouse1' class='st-nowarehouse1'></div> with the Stitch ${integration.name} integration`;
    }

    if (["Mailshake"].includes(integration.name)) {
        pageTitle = `${integration.name} & Stitch Integration: ETL Data to Your Data Warehouse`;
    }

    if (integration.company_link) {
        if (integration.SupressDoubleUseOfNameInSubtitle) {
            pageSubHeading = `<a href='${integration.company_link}' target='_blank'>${integration.name}</a> ${integration.short_description}. The Stitch integration allows you to seamlessly ETL ${integration.name} data to your warehouse, giving you access to raw customer data, without the headache of writing and maintaining ETL scripts.<div id='nowarehouse2' class='st-nowarehouse2empty'></div>`;
        } else {
            pageSubHeading = `<a href='${integration.company_link}' target='_blank'>${integration.name}</a> ${integration.short_description}. The Stitch ${integration.name} integration will ETL ${integration.name} data to your warehouse, giving you access to raw customer data, without the headache of writing and maintaining ETL scripts.<div id='nowarehouse2' class='st-nowarehouse2empty'></div>`;
        }
    } else {
        if (integration.SupressDoubleUseOfNameInSubtitle || useConnectorTemplate) {
            if (useConnectorTemplate) {
                pageSubHeading = `The ${integration.name} integration gives you access to business critical data, without the headache of writing and maintaining ETL scripts.<div id='nowarehouse2' class='st-nowarehouse2empty'></div>`;
            } else {
                pageSubHeading = `"${integration.name} ${integration.short_description}. The Stitch integration lets you seamlessly ETL ${integration.name} data to your warehouse, giving you access to raw customer data, without the headache of writing and maintaining ETL scripts.<div id='nowarehouse2' class='st-nowarehouse2empty'></div>"`;
            }
        } else {
            pageSubHeading = `${integration.name} ${integration.short_description}. The Stitch ${integration.name} integration will ETL ${integration.name} data to your warehouse, giving you access to raw customer data, without the headache of writing and maintaining ETL scripts.<div id='nowarehouse2' class='st-nowarehouse2empty'></div>`;
        }
    }

    if (integration.enterprise) {
        pageHeading = `Interested in sending ${integration.name} data to your warehouse?`;
        pageSubHeading = `The Stitch team can customize an integration for your ${integration.name} data as part of a Stitch <a href='/pricing'>Enterprise plan</a>. Leave your email address to let us know you're interested in this integration.`;
    }

    if (integration.status === "Coming Soon") {
        pageSubHeading = `The Stitch ${integration.name} integration is currently in development. Enter your information below, and we’ll notify you the moment it enters beta.`;
    }

    if (integration.integration_category.name === "Databases") {
        if (integration.SupressDoubleUseOfNameInSubtitle) {
            pageSubHeading = `The Stitch integration will ETL your ${integration.name} data to your data warehouse. Perform analysis on ${integration.name} data, without the headache of writing and maintaining ETL scripts.`;
        } else {
            pageSubHeading = `The Stitch #{integration.name} integration will ETL ${integration.name} data to your data warehouse. Perform analysis on your ${integration.name} data, without the headache of writing and maintaining ETL scripts.`;
        }
    }

    return (
        <Layout
            menus={menus}
            headerType={pageContext.type}
            headerItems={[integration]}
            pageHeading={pageHeading}
            pageSubHeading={pageSubHeading}
            dual_cta={true}
        >
            <Seo
                title={pageTitle}
                description={pageDescription}
                slug={"/integrations/" + integration.url}
                bodyClass={bodyClasses}
            />
            <IntegrationCategory integration={integration} />
            {integration.customLandingPageContent && (
                <>
                    <section className="st-section database" id="custom">
                        <MarkdownContainer
                            content={integration.customLandingPageContent.customLandingPageContent}
                            containerClasses={`st-feature--thin`}
                        />
                    </section>
                    <SectionSeparator />
                </>
            )}
            {selectedVideo && (
                <VideoContainer
                    videoEmbedCode={selectedVideo[0].embedCode.embedCode}
                    caption={selectedVideo[0].caption.caption}
                />
            )}
            <IntegrationFeaturesSection integration={integration} />
            {integration.endpoints && <IntegrationEndpointsGrid integration={integration} />}
            <IntegrationDocumentation integration={integration} />
            <DestinationsBoxSection
                heading={`${integration.name} to your data warehouse in minutes`}
                paragraph={`ETL your ${integration.name} data to the destination of your choice.`}
                integrationUrl={`/integrations/${integration.url}/`}
            />
            <AnalyticsBlocks integration={integration} />
            <SectionSeparator />
            {customerQuote ? (
                <TestimonialSection
                    quote={customerQuote[0].quote.quote}
                    author={customerQuote[0].quote_name}
                    position={`${customerQuote[0].quote_title}, ${customerQuote[0].company_name}`}
                />
            ) : (
                <TestimonialSection
                    quote={`We set up all of the integrations we needed in a matter of hours. Writing custom code to do all of that would have taken up a month's worth of back-end engineering time. Using Stitch allowed us to move forward without having to hire another person.`}
                    author={`Mark Marcantano`}
                    position={`Senior DevOps Engineer, Calm`}
                />
            )}
            <SectionSeparator />
            <WhyChooseStitch />
            <CTASection
                heading={`Start replicating your ${integration.name} data`}
                subheading={`Select your integrations, choose your warehouse, and enjoy Stitch free for 14 days.`}
                dualCTA={true}
                containerClasses={`st-section--lowlight`}
            />
            <IntegrationFilterSection
                heading={`Connect to your ecosystem of data sources`}
                paragraph={`Stitch integrates with leading databases and SaaS products. No API maintenance, ever, while you maintain full control over replication behavior.`}
                currentIntegrationName={integration.name}
            />
        </Layout>
    );
};

export default Integration;

export const query = graphql`
    query($slug: String!) {
        contentfulIntegration(url: { eq: $slug }) {
            type_id
            id
            url
            name
            logo {
                file {
                    url
                }
            }
            company_link
            enterprise
            short_description
            status
            integration_category {
                name
            }
            certified
            documentation_link
            setup_documentation_link
            gitHubRepository
            endpoints {
                integration_name
                schema_name
                description {
                    description
                }
            }
            customLandingPageContent {
                customLandingPageContent
            }
            customer {
                company_name
                quote_name
                quote_title
                quote {
                    quote
                }
            }
            SupressDoubleUseOfNameInSubtitle
            analytics_block {
                analytics_block_title
                analytics_package
                block_author
                block_published_date
                contentful_id
                description {
                    description
                }
                integrationName
                link_to_source
                code_snippet {
                    code_snippet
                }
            }
        }
    }
`;
